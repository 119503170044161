@import url("https://fonts.googleapis.com/css2?family=Lexend+Exa&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "Halfomania Regular";
  src: url("../fonts/Halfomania/Halfomania-Regular.ttf") format("truetype");
  src: url("../fonts/Halfomania/Halfomania-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Potra";
  src: url("../fonts/Potra/Potra.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trattatello";
  src: url("../fonts/Trattatello/Trattatello.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
