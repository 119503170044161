@import "../variables.scss";
@import "../functions.scss";
@import "../mixins.scss";

.header {
  align-items: center;
  display: flex;
  margin: 1rem 0;

  a {
    text-decoration: none;
  }
}

.openlaw-logo {
  margin: 0 auto;

  a {
    text-decoration: none;
  }
}

.page-nav {
  flex: 70%;
  padding-left: 2rem;
  padding-right: 3rem;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: em($bp-lg)) {
    padding-right: 1rem;
  }
}

.sector-tag {
  border-left: 1px solid #b9b9b9;
  color: white;
  margin-left: 1rem;
  padding-left: 1rem;
}

.title {
  font-family: "Potra", "Halfomania Regular";
  font-weight: 400;
  letter-spacing: 0.25em;
  // line-height: 7em;
  padding: 1em 3em 0;
  width: 100%;
  z-index: 0;
}

.title--center {
  margin-top: -3.3rem;
  padding: 0 0 0.3rem;
  text-align: center;
}

.title--rotate {
  transform: rotate(-18deg);
}

.tag-line {
  color: #70d85f;
  font-family: $font-body;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  line-height: 1.25;
  margin: 2rem 0;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 1px #6df4fa;
}
