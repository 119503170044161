@import "../variables";
@import "../mixins";
@import "../functions";
@import "./buttons.module";

// Input range thumb color override
$thumb-color: $color-keylargo;
@import "../inputrange";

%prefix-suffix-base {
  align-items: center;
  background: #212121;
  color: #6ef4fa;
  display: inline-flex;
  font-family: inherit;
  font-size: 1em;
  height: calc(100% - 4px);
  justify-content: center;
  margin: 0;
  padding: 0 0.5em;
  position: absolute;
  top: 2px;
  width: 2.625em;
  word-break: break-word;
  z-index: 0;
}

.text-description {
  border-bottom: 1px solid #676767;
  padding: 0 0 2rem 0;
  font-size: 1.2rem;
}

.green {
  color: #75f869;
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

input,
textarea,
select {
  font-family: $font-system-sans;
}

input[type="text"],
input[type="number"],
textarea {
  color: #fff;
  transition: border 0.25s ease;

  &:focus {
    border-color: $color-babyblue;
    box-shadow: 0 0 0 1px $color-babyblue inset;
    transition: box-shadow 0.25s ease;
  }
}

input[type="file"] {
  @include assistive-hidden;
}

input:disabled {
  cursor: not-allowed;
}

label {
  color: white;
  font-size: 1rem;
}

.help {
  color: $color-litesmoke;
  font-size: 1em;
}

.input-prefix__wrap,
.input-suffix__wrap {
  position: relative;
}

.input-prefix--sm[type="text"],
.input-prefix--sm[type="number"],
.input-suffix--sm[type="text"],
.input-suffix--sm[type="number"] {
  margin: 0 !important; // override other margins set in @media
}

.input-prefix--sm[type="text"],
.input-prefix--sm[type="number"] {
  padding-left: 4em;
}

.input-suffix--sm[type="text"],
.input-suffix--sm[type="number"] {
  padding-right: 4em;
}

.input-prefix__item--sm {
  @extend %prefix-suffix-base;

  border-right: 1px solid #333;
  border-radius: 1em 0 0 1em;
  left: 1px;
}

.input-suffix__item--sm {
  @extend %prefix-suffix-base;

  border-left: 1px solid #333;
  border-radius: 0 1em 1em 0;
  right: 1px;
}

.label--column,
.label--column span {
  display: block;
  margin: 2rem 0 1rem 0;
}

input[type="text"],
input[type="number"] {
  background-color: transparent;
  border: 1px solid #fff;
  margin: 0;
  outline: none;
  padding: 0;
  color: white;
  font-size: rem(18);
  padding: em(18);
  width: 100%;
  border-radius: 1rem;
  box-sizing: border-box;
}

input.width-25 {
  width: 25%;
}

input[type="file"] {
  &:focus + button,
  & + button:active,
  & + button:focus,
  & + button:hover {
    @extend .primary;
  }
}

textarea {
  background-color: transparent;
  outline: none;

  border-radius: 1rem;
  border: 1px solid #fff;
  color: white;
  font-size: rem(18);
  min-height: rem(300) - rem(18);
  padding: 1em;
  width: calc(100% - 2em - 4px);
}

// Thanks to: https://www.filamentgroup.com/lab/select-css.html;
select {
  display: block;
  font-size: 1.125rem;
  color: #fff;
  padding: 1.125em 3em 1.125em 1.125em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #000000 0%, #000000 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 0.875em auto, 100%;
  transition: border-color 0.25s ease;
}

select::-ms-expand {
  display: none;
}

select:focus,
select:active {
  border-color: $color-babyblue;
  outline: none;
}

select option {
  font-weight: normal;
}

.input-suffix {
  display: block;
  margin-left: 0.5em;
}

.input-prefixsuffix-wrap {
  align-items: center;
  display: flex;
}

/* FIELD ERRORS */

.error {
  color: $color-brightsalmon;
  text-align: left;
}
