@import "../variables";
@import "../functions";
@import "../mixins";

.member-details-wrapper {
  background: black;
  padding: 1rem;
  display: flex;
  flex: 1 100% !important;
  margin: 2rem 0;
  border: 1px solid #737373;
  border-radius: 1rem;

  @include size-medium-down {
    flex-direction: column-reverse;
  }

  @media only screen and (min-width: em($bp-xl)) {
    margin-top: 3.5rem;
  }
}

.member-column {
  padding: 1.5rem 5rem 1.5rem 1rem;
  flex: 0 45% !important;
  min-width: 45% !important;

  @include size-medium-down {
    padding: 1rem;
  }

  p {
    @include fluid-type(
      $bp-sm,
      $bp-xl,
      12px,
      16px
    ); /* min font-size 0.75rem, max 1rem */

    line-height: 1.6;
    white-space: pre-line;
  }
}

.voting-history-column {
  padding: 1.5rem 2rem;
  // flex: 1;
  flex: 0 55% !important;

  @include size-medium-down {
    padding: 1rem;
  }
}

.error {
  color: $color-brightsalmon;
  margin: 3rem 0;
  text-align: center;
  width: 100%;
}

.no-voting-history {
  // text-align: center;
  color: rgb(245, 246, 80);
  font-size: 1rem;
}

.voting-history-title {
  font-size: 1.125rem;
  font-weight: 900;
  margin: 2.5rem 0;
}

.update-field {
  display: block;
  margin: 1rem 0;

  &:last-of-type {
    margin-bottom: 2rem;
  }
}

.preview-file-container {
  border: 1px solid #3c6b7e;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  height: 80vw;
  max-height: 350px;
  margin: 0;
  padding: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb {
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.preview-file {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.no-image-available {
  display: "flex";
  justify-content: center;
  align-items: center;
}
