@import "../variables";

.container {
  outline: none;

  input:disabled + .text {
    cursor: not-allowed;
  }
}

.text {
  border-radius: 1rem;
  border: 1px dashed white;
  color: $color-litesmoke;
  cursor: pointer;
  display: block;
  padding: 1.125em;
  text-align: center;
  transition: border 0.25s ease;

  &--drag-active {
    border: 1px solid $color-babyblue;
  }
}

.list {
  list-style: none;
}

.listItem {
  background: url("../../images/file.svg") no-repeat left center;
  background-size: 1.5rem;
  color: $color-babyblue;
  display: flex;
  padding: 1rem 2.5rem;
  word-break: break-word;
}

.file-remove-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  margin-left: 0.5rem;

  /* "X" button */
  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

.thumb {
  display: inline-flex;
  width: 95%;
  height: 95%;
  justify-content: center;
  align-items: center;
}

.preview-file {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
