@import "../variables.scss";
@import "../hamburger.scss";
@import "../mixins.scss";

.menu-container {
  align-items: flex-end;
  flex-direction: column;
  display: flex;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-items {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  width: 100%;

  &.doc-item {
    justify-content: flex-start;
  }

  nav {
    margin-left: auto;
  }

  @media only screen and (max-width: 1179px) {
    nav,
    .get-connected-button {
      @include assistive-hidden;
    }
  }
}

.menu-items li {
  a {
    font-size: 1rem;
    letter-spacing: 0.09rem;
  }

  span {
    transition: color 0.25s ease-in-out;
  }

  @media only screen and (max-width: em($bp-lg)) {
    @include assistive-hidden;
  }

  @media only screen and (min-width: em($bp-lg)) {
    margin: 0.6rem 1.8em 0.6rem 0;

    &:hover {
      cursor: pointer;

      span {
        color: $color-babyblue;
        transition: transform 0.12s ease-in-out, color 0.25s ease-in-out;
      }
    }
  }
}

.modal-menu-connected-button {
  border-bottom: 1px solid #75fa6b;
  padding-bottom: 2rem;
  margin: 4rem 2rem 0.5rem;
  display: flex;

  button {
    width: 100%;
  }
}

.menu-modal-wrapper {
  outline: none;

  &:focus {
    outline: none;
  }
}

.with-projects-item {
  .menu-modal-wrapper {
    margin-left: auto;
  }

  nav {
    margin-left: 0;
  }
}

.menu-modal-hamburger {
  color: $color-babyblue;
  display: inline-block;
  margin-right: 2rem;
  outline: none;
  width: 1.8rem;

  &:hover {
    cursor: pointer;
  }
}

.projects-button {
  font-size: 0.9rem !important;
  padding: 0.5em em(24);
  display: flex;
  align-items: center;
  justify-content: center;

  .projects-button-icon {
    margin-left: 12px;
    font-size: 1.5rem;
  }
}
