/* body */

html,
body {
  font-family: "Space Mono", monospace;
  letter-spacing: 1px;
  background-color: #f8fafd;
  color: #3c6b7e;
}

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
  font-weight: 700;
}

/* common text */

p,
a {
  color: #3c6b7e;
}

.org-bold {
  font-weight: bold;
}

/* header */

.org-header-title {
  padding: 0 2rem;
}

/* page title */

.org-titlebar__title {
  text-shadow: 4px 4px 12px rgba(102, 136, 160, 0.1);
}

/* cards */

.org-card {
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
  margin-top: 4rem;
}

@media only screen and (max-width: 576px) {
  .org-card {
    margin-top: 2rem;
  }
}

/* shared modal styles */

.org-modal-overlay {
  background: rgba(248, 250, 253, 0.8);
}

.org-modal {
  background-image: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
  padding: 0;
  margin-top: 10vh;
}

.org-modal .card {
  border-radius: 0;
  background-color: transparent;
}

.org-modal-close {
  width: 1rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #ffffff;
  cursor: pointer;
}

.org-notification.info {
  color: #ff6f6f;
}

/* shared button styles */

.org-primary-button,
.org-secondary-button {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.6rem 1rem;
  font-size: 1.125rem;
  font-family: "Space Mono", monospace;
  font-weight: bold;
  min-width: 12rem;
}

.org-secondary-button:hover {
  color: #3c6b7e;
  border-color: #3c6b7e;
}

.org-mini-modal-button,
button.org-mini-modal-button {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  font-style: italic;
  font-family: "Space Mono", monospace;
  font-weight: bold;
}

@media only screen and (max-width: 576px) {
  .org-mini-modal-button,
  button.org-mini-modal-button {
    font-size: 1rem;
  }
}

.org-mini-modal-button:hover,
button.org-mini-modal-button:hover {
  color: #3c6b7e;
}

/* shared form elements */

.org-form-wrap {
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
  padding: 2rem 1.5rem;
  margin: 2rem auto;
  max-width: 70rem;
  box-sizing: border-box;
}

.org-form-description p {
  font-size: 1.125rem;
}

.org-input-row-label,
.org-label--column {
  color: #3c6b7e;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3;
}

input[type="text"],
input[type="number"],
textarea,
select {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #3c6b7e;
  color: #3c6b7e;
  font-family: "Space Mono", monospace;
  line-height: 1.3;
  padding: 1em;
}

select {
  padding-right: 3em;
}

input[type="text"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
  color: #3c6b7e;
  opacity: 0.7;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: #3c6b7e;
  box-shadow: 0 0 0 1px #3c6b7e inset;
  transition: box-shadow 0.25s ease;
}

textarea {
  min-height: 8rem;
}

/* change autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: rgba(255, 255, 255, 0.2) !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #3c6b7e;
}

.org-input-row-help {
  color: #3c6b7e;
}

.org-input-row-help--alert {
  color: #ff6f6f;
  font-weight: bold;
}

.org-input-prefix__item--sm {
  background: #ffffff;
  color: #3c6b7e;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 1px 1px 0;
  border-radius: 1rem 0 0 1rem;
  font-size: 1.125rem;
  top: 1px;
  width: 2.4em;
}

.org-input-suffix__item--sm {
  background: #ffffff;
  color: #3c6b7e;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 0 1px 1px;
  border-radius: 0 1rem 1rem 0;
  font-size: 1.125rem;
  top: 1px;
  width: 2.4em;
}

.org-radio-yesno[type="radio"] + label {
  color: #3c6b7e;
  opacity: 0.7;
}

.org-radio-yesno[type="radio"] + label:focus,
.org-radio-yesno[type="radio"] + label:active,
.org-radio-yesno[type="radio"] + label:hover,
.org-radio-yesno[type="radio"]:focus + label {
  border-color: #3c6b7e;
  color: #3c6b7e;
  opacity: 1;
}

.org-radio-yesno[type="radio"]:checked + label {
  border-color: #3c6b7e;
  color: #ffffff;
  background-color: #3c6b7e;
  opacity: 1;
}

.org-radiolist-label {
  color: #3c6b7e;
}

.org-radiolist-input[type="radio"] {
  background-color: #ffffff;
  box-shadow: #3c6b7e 0px 0px 0px 2px inset;
}

.org-radiolist-input[type="radio"]:hover {
  box-shadow: #3c6b7e 0px 0px 0px 2px inset;
}

.org-radiolist-input[type="radio"]:checked {
  box-shadow: #3c6b7e 0px 0px 0px 7px inset;
}

/* special styling for slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #3c6b7e;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
  border: 2px solid #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #3c6b7e;
  cursor: pointer;
}

.org-file-preview-container {
  display: flex;
}

.org-dropzone-container {
  flex-grow: 1;
  margin-right: 1rem;
}

.org-preview {
  border: 1px solid #3c6b7e;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  height: 12rem;
  margin: 1rem 0 0;
  flex-basis: 40%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.org-filedropzone-text {
  border: 1px dashed #3c6b7e;
  color: #3c6b7e;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  padding: 1em;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
}

.org-filedropzone-text--drag-active {
  border: 1px solid #3c6b7e;
}

.org-filedropzone-list {
  padding: 0;
}

.org-filedropzone-listItem {
  color: #3c6b7e;
  padding: 0;
  background: none;
}

@media only screen and (max-width: 767px) {
  .org-file-preview-container {
    flex-direction: column-reverse;
    align-items: initial;
  }

  .org-dropzone-container {
    margin-right: 0;
  }

  .org-preview {
    flex-basis: auto;
  }
}

.org-autocomplete-menu {
  background: #fbd9b4;
  box-shadow: 0 0 0 1px #3c6b7e inset;
  font-family: "Space Mono", monospace;
  font-size: 1.125rem;
  margin: -0.77rem 0 0 0;
  z-index: 1;
  padding: calc(1.125em + 0.5em) 1.125em 1.125em;
}

.org-autocomplete-item,
.org-autocomplete-item--default {
  color: #3c6b7e;
  font-size: 1.125rem;
  font-weight: normal;
}

.org-autocomplete-item:active,
.org-autocomplete-item:hover,
.org-autocomplete-item:focus,
.org-autocomplete-item[aria-selected="true"] {
  color: #3c6b7e;
}

.org-select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%233c6b7e%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

.org-form-section-heading {
  color: #3c6b7e;
  font-size: 1.3rem;
}

.org-form-section-heading:first-of-type {
  margin-top: 1rem;
}

.org-checkbox-label {
  color: #3c6b7e;
  font-size: 1rem;
}

.org-checkbox-input:checked + .org-checkbox-label .org-checkbox-box {
  background-color: #3c6b7e;
}

.org-checkbox-input:checked + .org-checkbox-label .org-checkbox-box:after {
  color: #ffffff;
  font-family: "Lexend Exa", sans-serif;
}

.org-confirm-checkbox-label.org-checkbox-label {
  width: 90%;
}

@media only screen and (max-width: 576px) {
  .org-form-wrap {
    margin: 2rem auto;
    padding: 2rem 1rem;
  }

  .org-form-description {
    width: calc(100% - 1rem);
  }

  .org-input-row-label,
  input[type="text"],
  input[type="number"],
  textarea,
  select,
  .org-input-prefix__item--sm,
  .org-input-suffix__item--sm,
  .org-radio-yesno[type="radio"] + label,
  .org-radio-yesno[type="radio"] + label:focus,
  .org-radio-yesno[type="radio"] + label:active,
  .org-radio-yesno[type="radio"] + label:hover,
  .org-radio-yesno[type="radio"]:focus + label,
  .org-radio-yesno[type="radio"]:checked + label,
  .org-input-row-text,
  .org-label--column,
  .org-radiolist-label,
  .org-filedropzone-text,
  .org-autocomplete-menu,
  .org-autocomplete-item,
  .org-autocomplete-item--default,
  .org-checkbox-label,
  .org-form-description p {
    font-size: 1rem;
  }

  .org-form-section-heading {
    font-size: 1.1rem;
  }
}

.org-submit-status-container {
  text-align: center;
  height: 3rem;
  max-width: 25rem;
  margin: 0 auto;
}

/* shared error message */

.org-error-message {
  font-weight: bold;
}

/* nav bar and slide out menu */

.org-modal-menu-container {
  background-color: #f8fafd;
  border-left: 2px solid #dedede;
}

.org-modal-menu-overlay {
  background: rgba(248, 250, 253, 0.8);

  transition: opacity 100ms ease-in-out;
}

.org-modal-menu {
  font-family: "Lexend Exa", sans-serif;
}

nav ul li {
  margin: 0 0 0 3rem;
  font-size: 18px;
}

nav ul li a,
.org-modal-menu li.org-profile-link a,
.org-menu-modal-hamburger,
.org-modal-menu-close {
  color: #3c6b7e;
}

.org-profile-link img {
  transform: translateY(3px);
}

nav ul li a.active,
nav ul li a:hover,
nav ul li a:hover span,
.org-menu-items li:hover span {
  color: #2498bd;
}

.org-modal-menu li.org-admin-link a,
nav .org-modal-menu li.org-admin-link a:hover span {
  color: #ee8257;
}

.org-get-connected-btn {
  font-family: "Lexend Exa", sans-serif;
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  text-transform: uppercase;
  padding: 0.5rem 0.8rem;
  background-clip: initial;
  height: 45px;
}

.org-get-connected-btn:before {
  display: none;
}

.org-get-connected-btn:hover {
  color: #3c6b7e;
}

.org-get-connected-btn--error {
  font-size: 0.85rem;
}

.org-get-connected-btn--error:hover {
  color: #ff7171;
}

.org-get-connected-btn--error::after {
  content: none !important;
}

.org-connected-address-link-button {
  color: #3c6b7e;
  background-color: rgba(214, 216, 218, 0.2);
  padding: 0.5rem;

  &:hover {
    opacity: initial;
  }
}

.org-get-connected-text {
  margin-left: 0.5rem;
}

.org-connection-ethaddress {
  text-transform: none;
}

.org-modal-menu-connected-button {
  border-bottom: 1px solid #3c6b7e;
  margin-top: 0;
}

.org-modal-menu-connected-button .org-get-connected-btn {
  width: initial;
}

.org-get-connected-icon {
  display: inline;
}

/* footer */

.org-footer-wrap {
  color: #3c6b7e;
  background-color: transparent;
}

.org-copyright-symbol {
  font-family: "Lexend Exa", sans-serif;
}

/* shared footer and mentors page styles */

.org-social-media-wrapper a,
.org-social-media-wrapper a:hover {
  color: #3c6b7e;
}

/* shared elements for proposals */

.org-sections-grid-container {
  background-color: transparent;
}

.org-sections-grid__header {
  color: #3c6b7e;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  padding: 0;
}

.org-proposal-card {
  border-radius: 0;
  border: 10px solid #c3d6dc;
}

.org-proposal-card:nth-of-type(4n + 1) {
  background-color: #fee5ff;
}

.org-proposal-card:nth-of-type(4n + 2) {
  background-color: #ffe3d9;
}

.org-proposal-card:nth-of-type(4n + 3) {
  background-color: #ffffff;
}

.org-proposal-card:nth-of-type(4n + 4) {
  background-color: #fff0d9;
}

.org-proposal-card > p,
.org-proposal-card > div {
  color: #3c6b7e;
  font-size: 0.9rem;
}

.org-proposal-card .org-proposal-button {
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: #3c6b7e;
  border: 1px solid #c3d6dc;
}

.org-count-until-starts,
.org-count-until-ends,
.org-status,
.org-status.org-status--starts,
.org-status.org-status--ended,
.org-status.org-status--failed {
  color: #3c6b7e;
}

.org-last-seconds {
  color: #ff6f6f;
}

.org-square-root-votes-bar {
  height: 14px;
  background-color: #ffffff;
}

.org-square-root-bar:after {
  height: 14px;
  border-right: 2px solid #ffffff;
}

.org-square-root-votes-bar .org-yes-vote {
  height: 14px;
  background-color: #3c6b7e;
}

.org-square-root-votes-bar .org-no-vote {
  height: 14px;
  background-color: #e1a1a1;
}

.org-yes-percent,
.org-yes-check {
  color: #3c6b7e;
}

.org-no-percent,
.org-no-check {
  color: #d88d8d;
}

.org-titlebar__action {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  font-style: italic;
}

.org-proposal-wrapper,
.org-proposal-wrapper-alt {
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
}

.org-proposal-wrapper:first-of-type {
  border-radius: 0;
}

.org-proposal-wrapper:last-of-type {
  border-radius: 0;
}

.org-proposal-content-title {
  color: #3c6b7e;
}

.org-eth-requested,
.org-vote-indicator-badge {
  color: #3c6b7e;
  background-color: #ffffff;
  font-size: 0.8rem;
}

.org-countdown {
  font-size: 0.9rem;
}

.org-proposal-details button {
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: #3c6b7e;
  border: 1px solid #3c6b7e;
}

.org-loader {
  border: 5px solid rgba(25, 138, 214, 0.2);
  border-top: 5px solid white; /* must be after border */
}

.org-loader-text {
  color: #3c6b7e;
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
}

.org-voting-button.org-selected[data-vote="1"] {
  background-color: #3c6b7e !important;
  border: 1px solid #3c6b7e !important;
}

.org-voting-button.org-selected[data-vote="2"] {
  background-color: #e1a1a1 !important;
  border: 1px solid #e1a1a1 !important;
}

.org-proposal-data-container a {
  color: #3c6b7e;
}

.org-toggle-text--selected,
.org-privacy__confirmMessage {
  color: #3c6b7e;
}

.org-proposal-data-container {
  background-color: #ffffff;
  border-radius: 0;
  border: 2px solid #c3d6dc;
}

.org-proposal-data-title,
.org-info-item span:first-child,
.org-contract-info-item span:first-child,
.org-proposer-info__title {
  color: #3c6b7e;
}

.org-switch {
  background: #ffffff;
}

.org-switch--off {
  box-shadow: 0 0 0 0.025em #3c6b7e inset;
}

.org-switch--on {
  box-shadow: 0 0 0 0.025em #c3d6dc inset;
}

/* members */

.org-tab-group ul li .org-tab {
  color: #3c6b7e;
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
}

.org-tab-group ul li .org-tab.org-selected:after {
  border-bottom: 0.3rem solid #3c6b7e;
}

.org-members-card {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid transparent;
  margin: 12px 3px;
}

.org-members-card:hover {
  color: #3c6b7e;
}

.org-connected-member {
  color: #3c6b7e;
  padding: 0 12px;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #c3d6dc;
}

.org-member-text-wrap span {
  margin: 0 1.25rem 0.5rem;
}

.org-member-text-wrap span:last-of-type {
  margin-bottom: 0;
}

/* member details */

.org-member-details-wrapper {
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.org-member-details-container {
  background-color: #ffffff;
  border-radius: 0;
  border: 2px solid #c3d6dc;
}

.org-member-section-title,
.org-voting-history-title,
.org-memberVote,
.org-memberVote .org-yes,
.org-memberVote .org-no {
  color: #3c6b7e;
}

.org-memberVote .org-pending span,
.org-memberVote .org-passed span,
.org-memberVote .org-failed span {
  padding: 0;
}

.org-memberVote .org-pending span:before,
.org-memberVote .org-passed span:before,
.org-memberVote .org-failed span:before {
  display: none;
}

.org-contract-info-item,
.org-contract-info-item a,
.org-voting-delegated {
  color: #2498bd;
}

.org-contract-info-item:first-child {
  margin-top: 0;
}

.org-delegate-voting-container button {
  background: none;
  color: #61d1ff;
  margin-top: 0.5rem;
}

.org-delegate-voting-container button:hover {
  opacity: 0.7;
}

.org-member-info-button {
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: #3c6b7e;
  border: 1px solid #3c6b7e;
}

.org-no-voting-history {
  color: #3c6b7e;
}

/* governance */

.org-proposal-icon .org-proposal-icon-governance {
  background-color: #f1daf2;
}

.org-snapshot-preview {
  color: #3c6b7e;
}

/* member details modals */

.org-member-balances-heading {
  color: #3c6b7e;
}

.org-member-balances-copy:hover svg g {
  stroke: #3c6b7e;
}

.org-member-balances-section .org-mini-modal-button {
  font-size: 0.85rem;
}

.org-delegation-checkbox + label span:nth-child(2) {
  color: #3c6b7e;
}

.org-voting-delegated {
  color: #3c6b7e;
}

.org-revoke-delegate-small {
  color: #3c6b7e;
  padding: 0 2rem;
}

/* splash page */

.org-openlaw-logo {
  margin: 7.25rem auto 0;
}

.org-splash-flamingo {
  position: absolute;
  top: -15.3rem;
  left: calc(50% - 13.5rem);
  z-index: -1;
}

.org-splash-flamingo img {
  width: 23rem;
}

.org-splash-skull {
  position: absolute;
  top: 36rem;
  left: -14rem;
}

.org-splash-skull img {
  opacity: 0.2;
  width: 22rem;
}

.org-splash-donut {
  position: absolute;
  top: 43rem;
  right: 3rem;
  transform: rotate(45deg) !important;
}

.org-splash-donut img {
  opacity: 0.4;
}

.org-tears-stars img {
  width: 15rem;
}

.org-middle-links__button,
.org-middle-links .org-get-connected-btn {
  color: #3c6b7e;
  background-color: #fdbdeb;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.6rem 4rem;
  text-transform: initial;
  font-family: "Space Mono", monospace;
  font-weight: bold;
  min-width: 12rem;
  line-height: 3rem;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.org-middle-links__button:hover,
.org-middle-links .org-get-connected-btn:hover {
  background-color: #ffffff;
}

.org-middle-links {
  z-index: 10;
  position: relative;
  margin: 3rem 0 14.2rem;
}

.org-middle-links__link {
  font-size: 1.2rem;
}

.org-middle-links__link a {
  color: #ffffff;
  font-weight: bold;
}

.org-middle-links__link a:hover {
  opacity: 0.7;
  transition: ease-in-out;
}

.org-middle-links .org-get-connected-icon {
  display: none;
}

.org-submit-proposal__title {
  font-weight: bold;
  font-style: italic;
  text-align: left;
  font-size: 2rem;
  margin-bottom: 7rem;
  color: #7e7e7e;
}

.org-submit-proposal__text {
  font-size: 1.125rem;
  color: #606735;
}

.org-submit-proposal-container {
  z-index: 2;
  position: relative;
}

.org-submit-proposal {
  background-color: #f5fdd6;
  border-radius: 0;
  border: 10px solid #f2b9b7;
  padding: 1.5rem 4rem 2rem;
}

.org-dao-data {
  display: inline-block;
  padding: 0.5rem 5rem;
}

.org-dao-balances-container {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.org-dao-data-stat {
  letter-spacing: 0.06rem;
  text-transform: lowercase;
  color: #ffd5d2;
  font-weight: bold;
  font-size: 2.2rem;
  margin: 0.25rem 0;
  text-shadow: 2px 2px 2px rgba(188, 29, 29, 0.25);
}

.org-nft-tagline {
  color: #3c6b7e;
  font-size: 1.4rem;
  letter-spacing: 1rem;
  text-align: right;
  position: absolute;
  right: 17rem;
  top: -3rem;
}

.org-tag-line {
  color: #fff;
  font-family: "Space Mono", monospace;
  font-size: 2rem;
  text-shadow: 2px 2px 2px rgba(188, 29, 29, 0.25);
  letter-spacing: 12px;
  margin: 2rem 0 0;
}

.org-projects-button {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.5rem 1.6rem;
  font-style: italic;
  text-transform: lowercase;
  font-weight: bold;
  min-width: initial;
  font-size: 1.2rem !important;
}

.org-projects-button:hover {
  color: #3c6b7e;
}

.org-recent-investments {
  z-index: 3;
  position: relative;
}

/* SPLASH FEATURE SWITCH STYLES */
.deployed-capital .org-tag-line {
  margin-top: 1.1rem;
}

.deployed-capital .org-middle-links {
  margin: 2rem 0 1rem;
}

.deployed-capital .org-recent-investments {
  margin-bottom: 2.2rem;
}

.deployed-capital .org-submit-proposal__title {
  margin: 4rem 0 2rem;
}

/* SPLASH SMALL WIDTH ADJUSTMENTS (optimized for iPhone X and iPad) */
@media only screen and (max-width: 768px) {
  .org-nft-tagline {
    font-size: 1.2rem;
    letter-spacing: 0.5rem;
    width: 16em;
    top: -3rem;
    right: 9.75rem;
  }
  .org-openlaw-logo {
    margin: 9rem auto 0;
  }
  .org-splash-flamingo {
    position: absolute;
    top: -5rem;
    left: calc(50% - 12rem);
    z-index: -1;
  }
  .org-splash-flamingo img {
    width: 21rem;
  }
  .org-middle-links {
    margin: 3rem 0 14.6rem;
  }
  .deployed-capital .org-recent-investments {
    margin-bottom: 1rem;
  }
  .org-submit-proposal__title {
    margin-bottom: 6rem;
    margin: 4.5rem 0 2rem;
  }
  .org-splash-donut {
    display: none;
  }
  .deployed-capital .org-submit-proposal__title {
    margin: 0.5rem 0 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .org-nft-tagline {
    font-size: 0.9rem;
    letter-spacing: 0.5rem;
    width: 14em;
    top: -1.1rem;
    right: 3.1rem;
  }
  .org-openlaw-logo {
    margin: 5.25rem auto 0;
  }
  .org-tag-line {
    font-size: 1.6rem;
  }
  .org-splash-flamingo {
    left: calc(50% - 10.5rem);
    top: -5.3rem;
  }
  .org-splash-flamingo img {
    width: 19rem;
  }
  .org-middle-links {
    margin: 3rem 0 8.5rem;
  }
  .org-middle-links__button,
  .org-middle-links .org-get-connected-btn {
    font-size: 1.2rem;
    padding: 0.5rem 3rem;
  }
  .org-middle-links__link {
    font-size: 1.1rem;
  }
  .org-submit-proposal {
    padding: 1.5rem 1.5rem 2rem;
  }
  .org-submit-proposal__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin: 2.5rem 0 2rem;
  }
  .org-submit-proposal__text {
    font-size: 1rem;
  }
  .org-dao-data-stat {
    font-size: 1.4rem;
  }
  .org-dao-data {
    padding: 0.5rem 0rem;
  }
  .org-tears-stars img {
    width: 10rem;
  }
}

/* verify accreditation pages */

.org-verify-wrap {
  max-width: none;
}

.org-verify-form-wrap {
  background-color: #ffffff;
  border-radius: 0;
  border-style: solid;
  border-color: #c3d6dc;
  border-width: 10px 10px 0;
  padding: 2rem 1.5rem;
  margin: 4rem auto 0;
  max-width: 70rem;
  box-sizing: border-box;
}

.org-verify-nav-wrap {
  background-color: #ffffff;
  border-radius: 0;
  border-style: solid;
  border-color: #c3d6dc;
  border-width: 0 10px 10px;
  padding: 2rem 1.5rem;
  margin: 0 auto 4rem;
  max-width: 70rem;
  box-sizing: border-box;
}

.org-verify-nav {
  max-width: 51rem;
  margin: auto;
}

.org-verify-nav > div {
  margin-top: 0;
}

.org-verify-start {
  max-width: 51rem;
  margin: auto;
}

.org-verify-form {
  width: 100%;
  max-width: 51rem;
  margin: auto;
}

.org-verify-subtitle {
  color: #3c6b7e;
  font-weight: bold;
}

.org-thankyou-text p {
  font-family: "Space Mono", monospace;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 1.5rem auto 0;
}

.org-thankyou-text p:first-of-type {
  margin-top: 5rem;
}

.org-emoji-moloch-wrapper {
  filter: none;
  animation: none;
  margin-top: 1.5rem;
}

.org-thankyou-social {
  margin: 2rem auto;
  padding: 0;
  justify-content: center;
}

.org-social-icon-wrap {
  background: none;
  margin: 0 1.5rem;
}

@media only screen and (max-width: 576px) {
  .org-verify-form-wrap {
    margin-top: 2rem;
    padding: 2rem 1rem;
  }

  .org-verify-nav-wrap {
    margin-bottom: 2rem;
    padding: 2rem 1rem;
  }

  .org-nav--left,
  .org-nav--right {
    justify-content: center;
  }

  .org-thankyou-text p {
    font-size: 1.2em;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .org-thankyou-text p:first-of-type {
    margin-top: 3rem;
  }
}

/* mentors */

.org-mentors-container {
  padding: 2rem 3rem;
}

.org-mentor-introduction {
  margin-bottom: 2rem;
}

.org-mentor-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
}

.org-mentor-container > div {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  width: 100%;
}

.org-mentor-card {
  margin: 0;
}

.org-mentor-card.org-mentor-card--is-clickable:hover {
  cursor: pointer;
  border: 1px solid #c3d6dc;
}

.org-mentor-card .org-mentor-contact {
  color: #3c6b7e;
}

.org-mentor-card .org-mentor-name {
  font-size: 1.125rem;
}

.org-mentor-card .org-mentor-emoji {
  margin-right: 2rem;
}

.org-mentor-card .org-mentor-bio,
.org-mentor-card .org-mentor-connect {
  padding-left: 0;
}

.org-mentor-card .org-mentor-bio {
  min-height: 3rem;
}

.org-mentor-card .org-mentor-bio:hover {
  opacity: 1;
  cursor: inherit;
}

@media only screen and (max-width: 768px) {
  .org-mentor-card .org-mentor-bio {
    min-height: 4.25rem;
  }
}

@media only screen and (max-width: 576px) {
  .org-mentors-container {
    padding: 2rem;
  }

  .org-mentor-container {
    grid-template-columns: 1fr;
  }

  .org-mentor-card .org-mentor-emoji {
    margin-right: 0.75rem;
  }
}

@media only screen and (min-width: 576px) {
  .org-mentor-card .org-mentor-bio {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .org-mentor-container {
    column-gap: 60px;
  }
}

/* mentor modal */

.org-modal .org-mentor-contact {
  color: #3c6b7e;
}

.org-modal .org-mentor-name {
  font-size: 1.125rem;
}

.org-modal .org-mentor-emoji {
  margin-right: 2rem;
}

@media only screen and (max-width: 576px) {
  .org-modal .org-mentor-emoji {
    font-size: 2rem;
    margin-right: 0.75rem;
  }

  .org-modal .org-mentor-bio {
    font-size: 1rem;
  }
}

/* privacy policy */

.org-privacy-policy tr,
.org-privacy-policy th:not(:last-child),
.org-privacy-policy td:not(:last-child) {
  border-color: #ffffff;
}

/* withdraw balances */

.org-withdraw-alert-text {
  color: #fbf79a;
  font-weight: bold;
}

.org-withdraw-balances__list {
  font-weight: bold;
  color: #3c6b7e;
}

.org-withdraw-balances__list a {
  color: #3c6b7e;
}

/* wallet connect modals */

.org-small-connected-address {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 0.8rem;
  opacity: 0.7;
  font-family: "Space Mono", monospace;
}

.org-wallet-badge-row {
  color: #3c6b7e;
  font-weight: bold;
}

.org-connected-address {
  font-family: "Lexend Exa", sans-serif;
  letter-spacing: normal;
  font-size: 0.9rem;
}

.org-connected-address:hover {
  opacity: 1;
}

.org-select-wallet-instructions {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.org-connectors-container .titlebar {
  margin-bottom: 3rem;
}

.org-connectors-container .org-options-container > button {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border-color: #3c6b7e;
  border-style: solid;
  border-width: 2px 2px 6px;
  padding: 0.6rem 1rem;
  font-family: "Space Mono", monospace;
  font-weight: bold;
}

.org-connectors-container .org-options-container > button:hover {
  background-color: #ffffff;
}

.org-connectors-container .org-options-container > button.org-connected:before {
  top: 15px;
}

.org-disconnect-link-button {
  height: 2rem;
  border-radius: 0;
  font-family: "Space Mono", monospace;
  font-weight: bold;
  font-size: 0.9rem;
  opacity: 1;
  color: #3c6b7e;
}

.org-connector-back-button {
  height: initial;
  border-radius: 0;
  font-family: "Space Mono", monospace;
  font-weight: bold;
  font-size: 0.9rem;
  opacity: 1;
  top: 0.5rem;
  left: 0.5rem;
  margin-top: 0;
}

.org-connectors-container .org-account-container .org-account-text {
  font-size: 0.95rem;
}

/* sale */

.org-sale-amount {
  animation: none;
  font-family: "Space Mono", monospace;
}

.org-sale-amount__unit-small {
  color: #ff6f6f;
}

.org-read-terms-button {
  color: #3c6b7e;
  background-color: #ffffff;
  border-radius: 0;
  border: 2px solid #3c6b7e;
  font-weight: 500;
}

.org-read-terms-button:hover {
  border-color: #3c6b7e;
}

.org-agreement-link {
  color: #3c6b7e;
}

.org-agreement-link:hover {
  color: #3c6b7e;
  filter: none;
}

.org-confirm-instruction-text {
  color: #3c6b7e;
}

.org-sale-modal-back {
  top: 0.5rem;
  left: 0.5rem;
  right: initial;
}

.org-sale-modal-button-back {
  color: #ffffff;
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
}

.org-sale-modal-button-back:hover {
  color: #ffffff;
  filter: none;
}

.org-sale-modal-link-button {
  color: #ffffff;
  font-weight: bold;
  font-size: 0.9rem;
}

.org-agreement-text-wrap {
  margin-bottom: 2rem;
}

/* proposal details voting modals */

.org-vote-confirm-highlighted.org-vote-confirm-yes {
  color: #3c6b7e;
}

.org-vote-confirm-highlighted.org-vote-confirm-no {
  color: #e1a1a1;
}

.org-vote-confirm-supplementary-text {
  color: #ff6f6f;
}

/* admin */

.admin .org-admin-button,
.admin .org-admin-button:hover {
  font-family: "Space Mono", monospace;
  letter-spacing: 2px;
  background-color: #ffffff;
  color: #3c6b7e;
  border: none;
  box-shadow: none;
}

.admin table button {
  background-color: #3c6b7e;
}

.admin table button:hover {
  box-shadow: 0 0 0 2px #eee;
  background-color: #3c6b7e;
  opacity: 0.4;
}

.admin table button:hover:disabled {
  opacity: 0.4;
}

.admin .container__editor {
  background: transparent;
}

.org-email-settings-save-confirm {
  color: #3c6b7e;
}
