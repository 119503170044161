@import "../variables";
@import "../mixins";
@import "../functions";

/* SHARED */

%font {
  color: white;
  display: block;
  line-height: 1.1;
  outline: none;
  font-family: $font-header--potra;
  font-weight: 200;
}

/* NFT MINTER */

.nft-minter {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    64px,
    96px
  ); /* min font-size 4rem, max 6rem */

  color: #3c6b7e;
  font-family: "Space Mono", monospace;
  text-transform: uppercase;
  font-size: 6em;
  font-weight: 700;
  text-shadow: 2px 2px 6px rgba(102, 136, 160, 0.1);
}

.nft-minter--small {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    30px
  ); /* min font-size 6rem, max 9rem */
}

.nft-minter--medium {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    24px,
    48px
  ); /* min font-size 6rem, max 9rem */
}

.nft-minter--large {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    54px,
    97px
  ); /* min font-size 6rem, max 9rem */
}
