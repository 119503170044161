@import "../variables";
@import "../mixins";

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  min-height: 50vh;
}

.menu-option {
  background-color: #ffffff;
  border-radius: 0;
  border: 10px solid #c3d6dc;
  padding: 1.5rem;
  margin: 2rem 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 400px;

  &:hover {
    cursor: pointer;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #3c6b7e;
    text-shadow: 4px 4px 12px rgba(102, 136, 160, 0.1);
    text-align: center;
  }

  &__icon {
    text-align: center;
    font-size: 4rem;
    margin: 1rem 0;
  }

  &__description {
    text-align: center;
    font-size: 0.9rem;
    max-width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: em($bp-md)) {
  .menu-container {
    flex-wrap: wrap;
    margin-top: 3rem;
  }

  .menu-option {
    margin: 1rem;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .menu-option {
    padding: 1rem;

    &__description {
      max-width: initial;
    }
  }
}
