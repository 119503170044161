@import "../variables";
@import "../mixins";

.options-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  > button {
    font-family: $font-mono;
    color: white;
    background-color: rgb(41, 44, 47);
    outline: currentcolor none medium;
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100% !important;
    display: flex;
    flex-direction: row;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0px;
    opacity: 1;
    font-size: 0.85rem;
    position: relative;

    &:hover {
      background-color: #44474a;
      transition: ease-in-out;
    }

    > span {
      width: 25px;
    }

    .wallet-name {
      width: auto;
    }

    &.connected {
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #75f86a;
        border-radius: 50px;
        left: 14px;
        top: 20px;
      }
      .wallet-name {
        margin-left: 1rem;
      }
    }
  }

  @include size-medium-down {
    display: flex;
    flex-direction: column;
  }
}

.disconnect-link-button {
  background: none;
  border: none;
  color: white;
  font-size: 0.8rem;
  margin-top: 1rem;
  text-decoration: underline;
  font-family: $font-body;
  opacity: 0.7;
  height: 3rem;
  border-radius: 1rem;
  cursor: pointer;
}

.connected-address {
  font-family: $font-mono;

  &:hover {
    opacity: 0.7;
  }
}

.connected-address-link-button {
  background: none;
  border: none;
  color: $color-babyblue;
  font-size: 1rem;
  margin: 1rem 0;
  text-decoration: underline;
  font-family: $font-mono;

  &:hover {
    opacity: 0.7;
  }
}

.small-connected-address,
.small-link-back {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 0.8rem;
  opacity: 0.7;
  font-family: $font-body;
}

.small-link-back {
  color: white;
  background: none;
  border: none;
  text-decoration: underline;
}

.small-error {
  color: $color-brightsalmon;
  border: 1px solid;
  border-radius: 12px;
  padding: 1rem;
  display: inline-block;
}

.emoji {
  font-size: 3rem;
}

.connector-modal {
  @include size-medium-down {
    padding: 2rem 1rem !important;
    min-height: 6rem !important;
  }
}

.select-wallet-instructions {
  font-size: 0.85rem;
}

.wrap {
  margin: 3rem auto 0;
  max-width: 62.5rem;
  position: relative;

  &.modalWrap {
    text-align: center;
    max-width: 52.5rem;

    @media only screen and (min-width: em($bp-sm)) {
      :global .card {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
      }
    }
  }
}

.input[type="text"] {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 1rem;
  color: white;
  font-size: 1.2rem;
  width: 90%;
  border-radius: 1rem;
  margin: 1rem 0 1.8rem 0;
}

.connection {
  align-items: center;
  background-color: black;
  border-radius: 1rem;
  border: 1px solid $color-deepsmoke;
  box-shadow: 0 0 1.875em rgba(255, 255, 255, 0.2);
  display: flex;
  font-family: $font-mono;
  font-size: 80%;
  padding: 0.4rem 0.8rem;

  button {
    text-align: left;
  }
}

.connection-blockie {
  display: flex;
  margin-right: 0.5rem;
}

.connection-ethaddress {
  display: inline-block;
}

.connection-ethaddress-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-family: $font-mono;
  font-size: 0.85rem;
  outline: none;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: #ea3bde;
  }
}

.get-connected-btn {
  background-color: #150c0c;
  border-radius: 8px;
  border: 1px solid #313131;
  color: white;
  display: inline-flex;
  font-family: $font-body;
  margin: 0;
  outline: none;
  padding: 0.8rem;
  text-transform: uppercase;
  transition: color 0.25s ease;

  &:hover {
    color: $color-babyblue;
  }
}

.get-connected-icon {
  display: none;
}

.sale-get-connected-btn {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  text-transform: none;
  font-size: 1rem;
  font-family: $font-mono;

  $sale-btn-border: 2px;
  color: #fff;
  background: rgb(61, 51, 66);
  background-clip: padding-box;
  border: solid $sale-btn-border transparent;
  border-radius: 1em;

  &.error {
    color: #ff7171;

    > span:first-of-type {
      margin-right: 18px;
    }
  }

  &.connection {
    padding: 0.8rem 1rem;

    .connection-ethaddress {
      padding-right: 1rem;

      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #75f86a;
        border-radius: 50px;
        right: 14px;
        top: 18px;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$sale-btn-border;
    border-radius: inherit;
    background: linear-gradient(to top, rgb(228, 80, 183), yellow);
  }

  &.error:before {
    background: #ff5a5a;
  }

  &.error:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    width: 8rem;
    border-radius: 1em;
    opacity: 0.3;
    border-right: 1px solid;
  }
}

.options-container {
  text-align: left;

  label {
    cursor: pointer;
  }
}

.wallet-icon {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
